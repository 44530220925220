.tags {
	text-align: center;
	padding: 20px 0px;
	background-color: #fff;
  height: 150px;
  padding: 1rem 5rem;
	z-index: 98;
	position: fixed;
  width: 100%;
}

/* .tag {
	outline: none;
	border: none;
	color: #000;
	margin: 0px 10px;
	background-color: transparent;
	cursor: pointer;
	margin-top: 90px;
}

.active {
	font-weight: bold;
	border-bottom: 1px solid #3f8fcd;
	color: #3f8fcd;
} */
.containerBook {
	width: 94%;
	margin: auto;
	display: grid;
	/* grid-template-columns: repeat(4, 1fr); */
	grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	transition: 0.3s ease-in-out;
	gap: 0px 20px;
	padding-bottom: 70px;
}

.image-card {
	padding: 5px;
	margin: 5px;
	/* border: 1px solid #ddd; */
	/* border-radius: 2px; */
}

.image {
	width: 100%;
	object-fit: cover;
	height: 366px;
}

.Book {
	margin: 0.5rem;
	text-align: center;
	font-size: clamp(1rem, 2vw, 1rem);
}
.BookP {
	margin: 0.5rem;
	text-align: center;
	font-size: clamp(0.9rem, 2vw, 0.9rem);
	color: #6B7EA2;
}

@media screen and (max-width: 768px) {
	.containerBook {
		display: block;
		padding-top: 10px;
	}
}