.hero-tour {
  /* background: #3da3d5; */
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  /* color: rgb(53, 53, 53); */
  color: #2F2E36;
  padding-top: 170px;
}

.hero-tour2{
  background: #3da3d5;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  /* color: rgb(53, 53, 53); */
  color: #2F2E36;
  /* padding-top: 170px; */
}

.title {
  /* font-size: clamp(2.1rem,6vw,4rem); */
  font-size: clamp(2.1rem,6vw,5rem);
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  /* margin-bottom: 80px; */
}

h3 {
  padding-top: 0.25em;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

.date {
  color: rgb(201, 251, 255);
}

#description {
  margin: 1.5em 0 2em 0;
}

.button {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white;
}
/* 
.workButton {
  background-color: #06d6a0;
}

.workButton:hover {
  background-color: #0ac593;
}

.schoolButton {
  background-color: #f9c74f;
}

.schoolButton:hover {
  background-color: #f3bc3c;
} */



.vertical-timeline {
    width: 95%;
    max-width: 1170px;
    margin: 0 auto;
    position: relative;
    padding: 7em 0 !important;
}
.vertical-timeline::before {
  background: #dfdede!important;
}

/* Icon container's border */
.vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px #ebe8e8, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05)!important;
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }
}